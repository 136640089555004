import Styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

export const CarouselWrapper = Styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Image = Styled(BackgroundImage)`
  width: 100%;
  height: 100vh;
  object-fit: cover;
`;
