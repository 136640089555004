import Styled from 'styled-components';

import { H1 } from '../../styles/commonStyles';
import { colors, fonts } from '../../styles/variables';

// eslint-disable-next-line import/prefer-default-export
export const StyledH1 = Styled(H1)`
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate3D(-50%, 50%, 0);
  z-index: 1;
  color: ${colors.lightAccent};
  text-shadow: 0.1rem 0.2rem ${colors.darkShade};
  text-transform: uppercase;
  font-family: ${fonts.merriweatherBold};
`;
