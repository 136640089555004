import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { CarouselWrapper, Image } from './Carousel.style';

const propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const Carousel = ({ slides }) => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    fade: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <CarouselWrapper>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Slider {...settings}>
        {slides &&
          slides.map((data, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Image key={index} fluid={data.image.localFile.childImageSharp.fluid} />
          ))}
      </Slider>
    </CarouselWrapper>
  );
};

Carousel.propTypes = propTypes;

export default Carousel;
