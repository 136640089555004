import React from 'react';
import PropTypes from 'prop-types';

import Container from '../Container/Container';
import Carousel from '../Carousel/Carousel';

import { StyledH1 } from './Hero.style';

const propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.shape({}),
      alt: PropTypes.string,
    }),
  ).isRequired,
  heading: PropTypes.string,
};

const defaultProps = {
  heading: null,
};

const Hero = ({ slides, heading }) => (
  <Container fullHeight>
    {heading && <StyledH1>{heading}</StyledH1>}
    <Carousel slides={slides} />
  </Container>
);

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
